<template>
   <section id="page-reports" class="mt-8">
      <v-card color="white" elevation="12" class="pa-2 mx-6 mb-6" outlined tile>
         <v-card-title class="align-start">
            <v-sheet color="accent" class="mt-n10" style="z-index: 1;" elevation="6" rounded>
               <v-icon dark size="52" class="pa-6">
                  mdi-printer
               </v-icon>
            </v-sheet>
         </v-card-title>
         <v-card flat outlined tile class="pull-top">
            <div class="grey lighten-4 pa-6 pt-5 space-left d-flex space-between">
               <div>
                  <base-title title="Custom Reports" class="text-uppercase mt-n1" space="0" />
                  <base-subtitle title="Fill all mandatory fields marks with asterisk *." />
               </div>
               <v-spacer />
               <v-select v-model="type" :items="filteredCategories" label="Category" placeholder="Pilih Kategori" outlined hide-details class="mt-n1 ml-4" style="max-width: 300px;"
                  :return-object="false" :menu-props="{ bottom: true, offsetY: true }" :disabled="!filteredCategories.length"
                  @change="changeCategory"
                  />
               <v-select v-model="report" :items="filteredReports" label="Report" placeholder="Pilih Laporan" outlined hide-details class="mt-n1 ml-4" style="max-width: 300px;"
                  item-text="text"
                  :return-object="true" :menu-props="{ bottom: true, offsetY: true }" :disabled="!filteredReports.length"
                  @change="changeReport"
                  />
            </div>
            <v-row class="mt-0 px-3" align="center" style="height: 92px;">
               <v-col cols="4" class="pt-0" />
               <v-col cols="8" class="pt-0 d-flex justify-end">
                  <template v-if="report.period && $hasRole(report.kind.toLowerCase(), 'P')">
                     <v-menu v-model="since.dateMenu" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                           <v-text-field :value="dateSince" label="Date Since" placeholder="Pilih Tanggal" outlined hide-details readonly
                              prepend-inner-icon="mdi-calendar-today"
                              class="ml-3" style="max-width: 140px;" v-bind="attrs" v-on="on"
                              />
                        </template>
                        <v-date-picker v-model="since.dateValue" :first-day-of-week="1" @change="since.dateMenu = false" />
                     </v-menu>
                     <v-menu ref="sinceMenuTime" v-model="since.timeMenu"
                        :close-on-content-click="false" :nudge-right="40" :return-value.sync="since.timeValue"
                        transition="scale-transition" offset-y max-width="290px" min-width="290px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                           <v-text-field v-model="since.timeValue" label="Time Since" placeholder="Pilih Jam" outlined hide-details readonly
                              prepend-inner-icon="mdi-clock-outline" :disabled="!since.dateValue"
                              class="ml-3" style="max-width: 120px;" v-bind="attrs" v-on="on"
                              />
                        </template>
                        <v-time-picker v-if="since.timeMenu" v-model="since.timeValue" full-width format="24hr" use-seconds @click:second="$refs.sinceMenuTime.save(since.timeValue)" />
                     </v-menu>
                     <v-menu v-model="until.dateMenu" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                           <v-text-field :value="dateUntil" label="Date Until" placeholder="Pilih Tanggal" outlined hide-details readonly
                              prepend-inner-icon="mdi-calendar-today"
                              class="ml-3" style="max-width: 140px;" v-bind="attrs" v-on="on"
                              />
                        </template>
                        <v-date-picker v-model="until.dateValue" :first-day-of-week="1" @change="until.dateMenu = false" />
                     </v-menu>
                     <v-menu ref="untilMenuTime" v-model="until.timeMenu"
                        :close-on-content-click="false" :nudge-right="40" :return-value.sync="until.timeValue"
                        transition="scale-transition" offset-y max-width="290px" min-width="290px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                           <v-text-field v-model="until.timeValue" label="Time Until" placeholder="Pilih Jam" outlined hide-details readonly
                              prepend-inner-icon="mdi-clock-outline" :disabled="!until.dateValue"
                              class="ml-3" style="max-width: 120px;" v-bind="attrs" v-on="on"
                              />
                        </template>
                        <v-time-picker v-if="until.timeMenu" v-model="until.timeValue" full-width format="24hr" use-seconds @click:second="$refs.untilMenuTime.save(until.timeValue)" />
                     </v-menu>
                  </template>
                  <v-btn x-large icon tile height="56" width="56" color="secondary" class="ml-3 mr-1" :loading="$store.state.process === 'tableReport'" @click="selectRecord()">
                     <v-icon size="52">
                        mdi-database-sync
                        <!-- mdi-sync -->
                     </v-icon>
                  </v-btn>
               </v-col>
            </v-row>
            <template v-if="report.kind && $hasRole(report.kind.toLowerCase(), 'P')">
               <v-divider />
               <v-row>
                  <!-- class="px-4" style="min-height: 418px;" -->
                  <v-col cols="12">
                     <!-- <n-member-summary v-if="report.name ==='MemberSummary'" :report="report" :records="records" :letter-head="letter.head" /> -->
                     <n-approval-summary v-if="report.name ==='ApprovalSummary'" :report="report" :records="records" :letter-head="letter.head" />
                     <n-presence-summary v-if="report.name ==='PresenceSummary'" :report="report" :records="records" :letter-head="letter.head" />
                  </v-col>
               </v-row>
            </template>
         </v-card>
      </v-card>
   </section>
</template>

<script>
const toDataURL = url => fetch(url)
.then(response => response.blob())
.then(blob => new Promise((resolve, reject) => {
   const reader = new FileReader()
   reader.onloadend = () => resolve(reader.result)
   reader.onerror = reject
   reader.readAsDataURL(blob)
}))
export default {
   name: 'SectionPageReports',

   components: {
      // NMemberSummary: () => import('@/components/report/NMemberSummary'),
      NApprovalSummary: () => import('@/components/report/NApprovalSummary'),
      NPresenceSummary: () => import('@/components/report/NPresenceSummary'),
   },

   data: () => ({
      isUsed: false,
      type: '',
      reports: [
         // { type: 'Person', kind: 'Member', name: 'MemberSummary', text: 'Member Summary', id: -1 },
         { type: 'Activity', kind: 'Approval', name: 'ApprovalSummary', text: 'Approval Summary', id: -1, period: true },
         { type: 'Transaction', kind: 'Presence', name: 'PresenceSummary', text: 'Presence Summary', id: -1, period: true },
      ],
      report: {},
      since: {
         dateMenu: false,
         dateValue: null,
         timeMenu: false,
         timeValue: null,
      },
      until: {
         dateMenu: false,
         dateValue: null,
         timeMenu: false,
         timeValue: null,
      },
      records: [],
      letter: { head: '' },
   }),

   computed: {
      filteredCategories () {
         return this.reports.filter(j => this.$hasRole(j.kind.toLowerCase(), 'P')).map(j => j.type).filter((value, index, self) => self.indexOf(value) === index)
      },
      filteredReports () {
         return this.reports.filter(j => j.kind === this.report.kind && this.$hasRole(j.kind.toLowerCase(), 'P'))
      },
      dateSince () {
         return this.since.dateValue ? this.$moment(this.since.dateValue).format('YYYY-MM-DD') : ''
      },
      dateUntil () {
         return this.until.dateValue ? this.$moment(this.until.dateValue).format('YYYY-MM-DD') : ''
      },
   },
   created () {
      toDataURL(require('@/assets/letter-head.png')).then(dataUrl => { this.letter.head = dataUrl })
   },
   mounted () {
      const { $route, since, until } = this
      // console.log('mounted ($route)', $route)
      if ($route.params.name) {
         const report = this.reports.find(j => j.name === $route.params.name)
         this.type = report.type
         this.changeReport(report)

         $route.params.since && $route.params.since.split(' ').map(s => s.length === 10 ? (since.dateValue = s) : (since.timeValue = s))
         $route.params.until && $route.params.until.split(' ').map(s => s.length === 10 ? (until.dateValue = s) : (until.timeValue = s))
         $route.params.since && $route.params.until && this.selectRecord()
      } else {
         const report = this.reports.find(j => j.name === 'ApprovalSummary') || this.reports[0]
         this.type = report.type
         this.changeReport(report)

         since.dateValue = this.$moment().startOf('month').format('YYYY-MM-DD')
         since.timeValue = '00:00:00'
         until.dateValue = this.$moment().endOf('month').format('YYYY-MM-DD')
         until.timeValue = '23:59:59'
      }
   },
   methods: {
      changeCategory (type) {
         switch (type) {
            case 'Activity': this.changeReport(this.reports.find(j => j.name === 'ApprovalSummary' || j.type === type))
               break
            case 'Transaction': this.changeReport(this.reports.find(j => j.name === 'PresenceSummary' || j.type === type))
               break
         }
      },
      changeReport (json) {
         this.report = Object.assign({}, json)
         this.records = []
      },
      async selectRecord () {
         const { report, since, until } = this
         // console.log('selectRecord (report)', JSON.parse(JSON.stringify(report)))
         const data = { headers: { command: 'tableReport' } }
         if (report.name === 'ApprovalSummary') {
            data.conditions = { kind: report.kind, name: report.name, since: `${since.dateValue} ${since.timeValue}`, until: `${until.dateValue} ${until.timeValue}` }
            const response = await this.$store.dispatch('mongoose', data)
            // console.log('selectRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
            this.records = response.data
         }
         if (report.name === 'PresenceSummary') {
            data.conditions = { kind: report.kind, name: report.name, since: `${since.dateValue} ${since.timeValue}`, until: `${until.dateValue} ${until.timeValue}` }
            const response = await this.$store.dispatch('mongoose', data)
            // console.log('selectRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
            this.records = response.data
         }
      },
   },
}
</script>

<style lang="scss" scoped>
.pull-top {
   margin-top: -92px;
}
.space-left {
   padding-left: 136px !important;
   height: 90px;
}
</style>
